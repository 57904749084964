<template>
    <div class="grey lighten-4 pa-3" style="margin:-12px -12px;height:100%;width:100vw;">
        <background />
        <top-nav title="Dashboard 💪">
            <template v-slot:actions>
                <v-menu bottom left class="rounded-xl" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon color="white">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense flat>
                        <v-list-item-group v-model="timePeriod" color="primary">
                            <v-list-item>
                                <v-list-item-avatar class="my-0">
                                    <v-icon>mdi-calendar-week</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title>Últimos 7 días</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar class="my-0">
                                    <v-icon>mdi-calendar-month</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title>Últimos 30 días</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar class="my-0">
                                    <v-icon>mdi-calendar</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title>Hoy</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </template>
        </top-nav>

        <!-- <div class="d-flex justify-space-between">
            <v-chip color="accent">
                <v-icon left>mdi-calendar-week</v-icon>
                7 días
            </v-chip>

            <v-chip>
                <v-icon left>mdi-calendar-month</v-icon>
                30 días
            </v-chip>

            <v-chip>
                <v-icon left>mdi-calendar</v-icon>
                Hoy
            </v-chip>
        </div> -->

        <div class="money mb-5" style="z-index:3;">
            <div class="grey--text text--darken-1">Total generado: {{ dateRange }}</div>
            <v-skeleton-loader :loading="loading" type="heading" min-height="60px" transition="fade-transition" class="d-flex justify-center align-center">
                <div class="text-h2 d-flex align-start justify-center mt-5 mb-1">
                    <span class="text-h5 mt-1">$</span>{{ data.totalProfit }}
                </div>
            </v-skeleton-loader>
            <div class="body-2 accent--text text-center d-flex justify-center align-center">
                <v-skeleton-loader :loading="loading" type="text" width="80" min-height="20px" transition="fade-transition" class="d-flex justify-center align-center">
                    <div>
                        <v-icon color="accent" small v-if="change > 0">mdi-arrow-up</v-icon>
                        <v-icon color="error" small v-else-if="change < 0">mdi-arrow-down</v-icon> 
                        <span v-if="change != 0">{{ `${change}% vs. año anterior` }}</span>
                        <span v-else>No hay cambio</span>
                    </div>
                </v-skeleton-loader>
            </div>
        </div>

        <div class="key my-5">
            <v-row no-gutters>
                <v-col cols="6" class="pb-2 pr-1">
                    <v-card flat outlined class="rounded-xl text-center key1">
                        <v-skeleton-loader :loading="loading" type="image" height="78px">
                            <div class="d-flex flex-no-wrap align-center" style="position:relative;overflow:hidden;">
                                <div>
                                    <v-card-title class="text-h4 pt-2">
                                        {{ data.orders }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-2">
                                        Órdenes
                                    </v-card-subtitle>
                                </div>
                            </div>
                        </v-skeleton-loader>

                        
                    </v-card>
                </v-col>

                <v-col cols="6" class="pb-2 pl-1">
                    <v-card flat outlined class="rounded-xl text-center key2">
                        <v-skeleton-loader :loading="loading" type="image" height="78px">
                            <div class="d-flex flex-no-wrap align-center" style="position:relative;overflow:hidden;">
                                <div>
                                    <v-card-title class="text-h4 pt-2">
                                        {{ data.customers }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-2">
                                        Clientes nuevos
                                    </v-card-subtitle>
                                </div>
                            </div>
                        </v-skeleton-loader>
                    </v-card>
                </v-col>

                <v-col cols="6" class="pb-2 pr-1">
                    <v-card flat outlined class="rounded-xl text-center key3">
                        <v-skeleton-loader :loading="loading" type="image" height="78px">
                            <div class="d-flex flex-no-wrap align-center" style="position:relative;overflow:hidden;">
                                <div>
                                    <v-card-title class="text-h4 pt-2">
                                        {{ data.products }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-2">
                                        Productos activos
                                    </v-card-subtitle>
                                </div>
                            </div>
                        </v-skeleton-loader>
                    </v-card>
                </v-col>

                <v-col cols="6" class="pb-2 pl-1">
                    <v-card flat outlined class="rounded-xl text-center key4">
                        <v-skeleton-loader :loading="loading" type="image" height="78px">
                            <div class="d-flex flex-no-wrap align-center" style="position:relative;overflow:hidden;">
                                <div>
                                    <v-card-title class="text-h4 pt-2">
                                        {{ data.sessions }}
                                    </v-card-title>

                                    <v-card-subtitle class="pb-2">
                                        Sesiones
                                    </v-card-subtitle>
                                </div>
                            </div>
                        </v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <div class="latest-orders outlined">
            <div class="grey--text text--darken-1 mt-2">Últimas 5 órdenes</div>

            <v-skeleton-loader :loading="loading" type="list-item-three-line@5">
                <v-list three-line class="pb-0">
                    <template v-for="order in data.lastOrders">
                        <v-list-item class="px-0" :key="order.id" :to="`/secure/order/${order.id}`">
                            <v-list-item-content>
                                <v-list-item-title>{{ order.orderNumber }}</v-list-item-title>
                                <v-list-item-subtitle v-if="order.customer != null">{{ order.customer.firstName }} {{ order.customer.lastName }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ toMoney( order.total ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip label x-small :color="getOrderStatusColor( order.status )" class="white--text">{{ order.status }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-skeleton-loader>

            <v-alert type="warning" :icon="false" text dense v-show="!loading && data.lastOrders.length == 0" class="text-center">
                No se hay órdenes nuevas
            </v-alert>
        </div>

        <div class="latest-customers outlined my-5">
            <div class="grey--text text--darken-1 mt-2">Últimos 5 clientes registrados</div>

            <v-skeleton-loader :loading="loading" type="list-item-three-line@5">
                <v-list three-line class="pb-0">
                    <template v-for="customer in data.lastCustomers">
                        <v-list-item class="px-0" :key="customer.id" :to="`/secure/customer/${customer.id}`">
                            <v-list-item-content>
                                <v-list-item-title>{{ customer.firstName }} {{ customer.lastName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ toPhone( customer.phone ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-skeleton-loader>

            <v-alert type="warning" :icon="false" text dense v-show="!loading && data.lastCustomers.length == 0" class="text-center">
                No se ha registrado nadie
            </v-alert>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopNav from '@/components/admin/TopNav';
import Background from '@/components/Background';
import moment from 'moment';
import { _st } from '@/softech';
import { API } from '@/inc/api';

export default {
    data: () => ({
        data                : null,
        timePeriod          : 0,
        loading             : false,
    }),
    computed: {
        ...mapGetters({
            user            : 'auth/user'
        }),
        from() {
            switch( this.timePeriod ) {
                case 0: return moment().subtract( 7, 'days' ).format('YYYY-MM-DD'); // .format('YYYY-MM-DDTHH:mm:ssS');
                case 1: return moment().subtract( 30, 'days' ).format('YYYY-MM-DD'); // .format('YYYY-MM-DDTHH:mm:ssS');
                case 2: return moment().format('YYYY-MM-DD'); // .format('YYYY-MM-DDT00:00:000');
            }
        },
        to() {
            return moment().format('YYYY-MM-DD'); // .format('YYYY-MM-DDTHH:mm:ssS');
        },
        dateRange() {
            if( this.timePeriod == 2 )
                return 'Hoy';

            return `${moment( this.from.split('T')[0] ).format('MMM DD, YYYY')} - ${moment( this.to.split('T')[0] ).format('MMM DD, YYYY')}`;
        },
        change() {
            if( this.data == null )
                return '';

            let changePerc = ( this.data.orders - this.data.lastYearOrders ) / ( this.data.lastYearOrders == 0 ? 1 : this.data.lastYearOrders ) * 100;
            return _st.toFixedNumber( changePerc, 0);
        }
    },
    watch: {
        async timePeriod() {
            await this.init();
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            try {
                let api = new API();

                this.loading = true;
                let res = await api.get(`/dashboard/${this.from}/${this.to}`);

                if( res.data.status !== true ) {
                    this.$root.$on('alert', { status: 'error', message: res.data.message });
                    return;
                }

                this.data = res.data.data;
                this.data.totalProfit = _st.toMoney( this.data.totalProfit ).substring( 1 );
            }
            catch( error ) {
                this.$root.$on('alert', { status: 'error', message: error });
                console.log(error);
            }
            finally {
                this.loading = false;
            }
        },
        toMoney(v) {
            return _st.toMoney(v);
        },
        getOrderStatusColor( status ) {
            switch( status ) {
                case 'processing'       : return 'orange';
                case 'completed'        : return 'green';
                case 'cancelled'        : return 'blue-grey';
                case 'pending payment'  : return 'amber';
                case 'shipped'          : return 'teal';
                case 'failed'           : return 'red';
                case 'draft'            : return 'grey';
                case 'refunded'         : return 'purple';
            }
        },
        toPhone(v) {
            return _st.toPhoneNumber( v );
        }
    },
    components: {
        TopNav, Background
    }
}
</script>

<style lang="scss" scoped>
.money,
.latest-orders,
.latest-customers {
    padding: 15px;
    background-color: #fff;
    border-radius: 20px;
    position:relative;
}
.outlined {
    border: thin solid rgba(0, 0, 0, 0.12);
}
// .key1 {
//     background: rgb(238,58,140);
//     background: linear-gradient(129deg, rgba(238,58,140,.9) 0%, rgba(246,137,38,.9) 100%);
// }
// .key2 {
//     background: rgb(238,58,140);
//     background: linear-gradient(129deg, rgba(246,137,38,.9) 0%, rgba(238,58,140,.9) 100%);
// }
// .key3 {
//     background: rgb(238,58,140);
//     background: linear-gradient(-129deg, rgba(246,137,38,.9) 0%, rgba(238,58,140,.9) 100%);
// }
// .key4 {
//     background: rgb(238,58,140);
//     background: linear-gradient(-129deg, rgba(238,58,140,.9) 0%, rgba(246,137,38,.9) 100%);
// }
</style>